import { CountUp } from 'countup.js';

export default (targetCount, decimalPlaces) => ({
    countUp: null,

    init() {
        if (targetCount) {
            this.countUp = new CountUp(this.$refs.num, targetCount, {
                decimalPlaces,
                separator: '’',
            });
        }
    },

    start() {
        if (this.countUp) {
            this.countUp.start();
        }
    },
});
