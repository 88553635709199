import Swiper from 'swiper';
import { Keyboard } from 'swiper/modules';

export default (numberOfSlides, hasVideos = false) => ({
    swiper: null,
    activeSlide: 1,

    init() {
        if (numberOfSlides > 1) {
            this.initSwiper();
        }
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    initSwiper() {
        if (this.swiper) {
            return;
        }

        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Keyboard],

            autoHeight: true,

            loop: !hasVideos,
            rewind: hasVideos,

            keyboard: {
                enabled: true,
            },
        });

        this.swiper.on('slideChange', () => {
            this.activeSlide = this.swiper.realIndex + 1;
        });
    },

    prev() {
        if (this.swiper) {
            this.swiper.slidePrev();
        }
    },

    next() {
        if (this.swiper) {
            this.swiper.slideNext();
        }
    },

    get slideNo() {
        return `${this.activeSlide}/${numberOfSlides}`;
    },

    get flipped() {
        return this.activeSlide;
    },
});
