import mediumZoom from 'medium-zoom';

export default () => ({
    zoom: null,

    init() {
        this.zoom = mediumZoom(this.$refs.root.querySelector('.zoomImage'), {
            margin: 40,
            background: '#ffffff',
        });

        this.zoom.on('open', (event) => {
            const zoomed = event.detail.zoom.getZoomedImage();
            zoomed.classList.remove('object-cover');
            zoomed.classList.remove('aspect-[1.78]');
            zoomed.classList.add('object-contain');

            // const img = event.detail.zoom.getImages()[0];
            // img.classList.remove('object-cover');
            // img.classList.remove('aspect-[1.78]');
            // img.classList.add('object-contain');
            this.zoomedIn = true;
        });

        this.zoom.on('close', (event) => {
            const zoomed = event.detail.zoom.getZoomedImage();
            zoomed.classList.remove('object-contain');
            zoomed.classList.add('object-cover');
            zoomed.classList.add('aspect-[1.78]');

            // const img = event.detail.zoom.getImages()[0];
            // img.classList.remove('object-contain');
            // img.classList.add('object-cover');
            // img.classList.add('aspect-[1.78]');

            this.zoomedIn = false;
        });

        this.$watch('activeSlide', () => {
            this.zoom.close();
        });
    },

    destroy() {
        this.zoom.detach();
    },
});
