import Swiper from 'swiper';
import { Keyboard } from 'swiper/modules';

export default () => ({
    swiper: null,
    initialised: false,
    hasMoved: false,

    init() {
        this.initSwiper();
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    initSwiper() {
        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Keyboard],
            keyboard: {
                enabled: true,
            },

            slidesPerView: 'auto',
            slidesOffsetBefore: 20,
            breakpoints: {
                390: { slidesOffsetBefore: 36 },
                768: { slidesOffsetBefore: 80 },
                1280: { slidesOffsetBefore: 140 },
            },
            on: {
                sliderFirstMove: () => {
                    this.hasMoved = true;
                },
            },
        });

        this.initialised = true;
    },
});
